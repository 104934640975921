import React from "react"
import { Link } from "gatsby"

import { withMentorRequested } from "./withMentorRequested"
import { getFluidGatsbyImage, routeResolver } from "../Wrappers/Helpers"

import { ArticleCard } from "../Article/ArticleCard"
import { Grid } from "../Grid/Grid"
import { Hero } from "../Section/Hero/Hero"
import {
  Container,
  Responsive,
  GridColumn,
  HeroH1,
  HeroH4,
  Card,
  Row,
  Column,
  Button,
  CardButton,
  Image,
  InstagramCard,
  Title,
  Content,
  OverflowWrapper,
  CardButtonWrapper,
} from "./MentorRequested.styled"

export const MentorRequested = withMentorRequested(
  ({
    page,
    articles,
    items,
    template: { requestMentorsLink, requestPrepareLink, requestSubmitted, requestSubmittedSubtitle, requestSubmittedSocial, requestTipsTitle },
  }: Props): JSX.Element => (
    <>
      <Responsive screen={`md>`}>
        <Hero colour={`secondary`} size={`small`}>
          <Container width={`lg`}>
            <HeroH1 large>{page?.title}</HeroH1>
            <HeroH4 small>{requestSubmittedSubtitle}</HeroH4>
            {(requestMentorsLink || requestPrepareLink) && (
              <Row wrap>
                {requestMentorsLink && (
                  <Column position={`center`} width={`md:w-1/2`}>
                    <Button
                      as={Link}
                      to={routeResolver({ item: requestMentorsLink.document })}
                      title={requestMentorsLink.title}
                      theme={`primary`}
                      colour={`dark`}
                      size={`large`}
                    >
                      {requestMentorsLink.title}
                    </Button>
                  </Column>
                )}
                {requestPrepareLink && (
                  <Column position={`center`} width={`md:w-1/2`}>
                    <Button
                      as={Link}
                      to={routeResolver({ item: requestPrepareLink.document })}
                      title={requestPrepareLink.title}
                      theme={`tertiary`}
                      colour={`dark`}
                      size={`large`}
                    >
                      {requestPrepareLink.title}
                    </Button>
                  </Column>
                )}
              </Row>
            )}
          </Container>
        </Hero>
      </Responsive>
      <Responsive screen={`<md`}>
        <Card size={`full`} shadow={`medium`} background={`secondary`}>
          <Title>{requestSubmitted}</Title>
          <Content>{requestSubmittedSubtitle}</Content>
          {/* <Image image={{ ...getFluidGatsbyImage(image, { maxWidth: 500 }) }} /> */}
          {requestMentorsLink && (
            <OverflowWrapper>
              <Button
                as={Link}
                to={routeResolver({ item: requestMentorsLink.document })}
                title={requestMentorsLink.title}
                theme={`primary`}
                colour={`dark`}
                size={`large`}
              >
                {requestMentorsLink.title}
              </Button>
            </OverflowWrapper>
          )}
        </Card>
        {requestPrepareLink && (
          <CardButtonWrapper>
            <CardButton
              as={Link}
              to={routeResolver({ item: requestPrepareLink.document })}
              title={requestPrepareLink.title}
              theme={`minimal`}
              colour={`dark`}
              size={`medium`}
            >
              {requestPrepareLink.title}
            </CardButton>
          </CardButtonWrapper>
        )}
      </Responsive>

      {items?.length > 0 && (
        <Container collapse={`<md`} overflow={`hidden`} spacing={`md`} topSpacing={`md`}>
          <Grid show={4} overflow title={requestSubmittedSocial}>
            {items?.map(({ id, image, title }) => (
              <GridColumn key={id} width={`w-full`}>
                <InstagramCard background={`grey-pale`} size={`square`} noMargin>
                  <Image image={{ ...getFluidGatsbyImage(image, { maxWidth: 700 }) }} alt={title} title={title} />
                </InstagramCard>
              </GridColumn>
            ))}
          </Grid>
        </Container>
      )}

      {articles?.edges?.length > 0 && (
        <Container spacing={`lg`} width={`xl`}>
          <Grid title={requestTipsTitle}>
            {articles?.edges?.map(({ node: { categories, id, image, slug, summary, title, _type } }) => (
              <GridColumn key={id} width={`md:w-1/3`}>
                <ArticleCard
                  size={`full`}
                  image={getFluidGatsbyImage(image, { maxWidth: 700 })}
                  title={title}
                  content={summary}
                  link={`/articles/${slug?.current}`}
                  subtitle={categories?.map(({ title }) => title).join(` | `)}
                />
              </GridColumn>
            ))}
          </Grid>
        </Container>
      )}
    </>
  )
)

export interface Props {
  page: any
  items: any
  template: any
  articles: Array<any>
}
