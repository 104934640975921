import { useStaticQuery, graphql } from "gatsby"

import { useApp } from "./useApp"

export const useInstagram = () => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const { images } = useStaticQuery(graphql`
    query INSTGRAM_FEED {
      images: allSanityInstagram(limit: 8, sort: { fields: _createdAt, order: DESC }) {
        edges {
          node {
            ...GatsbyInstagramFragment
          }
        }
      }
    }
  `)

  const items = images?.edges?.map(({ node }) => ({
    ...node,
    products: node?.products?.map(product => ({ ...product, url: `${routes?.PRODUCT}/${product?.shopify?.shopifyHandle}` })),
  }))

  return {
    items,
  }
}
