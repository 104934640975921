import { GatsbyImage } from "gatsby-plugin-image"
import tw, { styled } from "twin.macro"

import { H1, P } from "../../styled/Text"
import { StyledButton } from "../../styled/Button"
import { StyledCard } from "../../styled/Card"
import { StyledColumn } from "../../styled/Column"
import { StyledRow } from "../../styled/Row"

export { StyledContainer as Container } from "../../styled/Container"
export { StyledGridColumn as GridColumn } from "../../styled/Grid"
export { StyledResponsive as Responsive } from "../../styled/Responsive"
export { StyledHeroH1 as HeroH1, StyledHeroH4 as HeroH4 } from "../../styled/Hero"

export const Card = tw(StyledCard)`text-center px-10 pb-12 mb-12 rounded-xxl`
export const Row = tw(StyledRow)`w-auto -mx-4 mt-12`
export const Column = tw(StyledColumn)`px-4`
export const Button = tw(StyledButton)`w-full`
export const CardButton = tw(StyledButton)`inline-block mx-auto`
export const Image = tw(GatsbyImage)`inline-block w-36 h-36 m-5 rounded-full max-w-full md:my-0 md:block md:w-80 md:h-auto md:mx-auto`
export const InstagramCard = styled(StyledCard)`
  ${tw`overflow-hidden`}
  > div {
    position: absolute !important;
    ${tw`inset-0`}
  }
`
export const Title = tw(H1)`text-white mb-1 text-xl font-bold md:text-2xl lg:text-5-5xl`
export const Content = tw(P)`text-white mb-2 px-4 font-bold text-base md:px-0 md:text-md lg:text-lg lg:mb-3`
export const OverflowWrapper = tw.div`absolute bottom-0 inset-x-0 -mb-3`
export const CardButtonWrapper = tw.div`text-center`
