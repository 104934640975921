import React, { useMemo } from "react"

import { useTemplate } from "../../hooks/useTemplate"
import { useInstagram } from "../../hooks/useInstagram"

export const withMentorRequested = Component =>
  React.memo(({ name = "MentorRequested", ...props }) => {
    const { mentor: template } = useTemplate()
    const { items } = useInstagram()

    Component.displayName = name
    return useMemo(() => <Component {...props} template={template} items={items} />, [])
  })
