import React from "react"
import { graphql } from "gatsby"

import { withAuthentication } from "../../hoc/withAuthentication"
import { withMembership } from "../../hoc/withMember"
import { MentorRequested as Page } from "../../components/Mentor/MentorRequested"

export const query = graphql`
  query {
    page: sanityPageRequested {
      title
      content: _rawContent(resolveReferences: { maxDepth: 10 })
    }
    articles: allSanityArticle(
      limit: 3
      filter: { attributes: { publishedAt: { ne: null } } }
      sort: { fields: attributes___publishedAt, order: DESC }
    ) {
      edges {
        node {
          ...GatsbyArticleFragment
        }
      }
    }
  }
`

export default withAuthentication(withMembership(({ data, ...props }) => <Page {...props} {...data} />))
